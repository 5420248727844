import React from "react";
import { Link } from "gatsby";
import { Layout, Row, Col, BackTop, Button } from "antd";
import { Helmet } from "react-helmet";

import CommonHeader from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import UseCaseSider from "../../components/useCases/use-cases-sider";

const UseCaseContentCalendar = ({}) => (
  <div>
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Content Calendar - DayViewer</title>
        <meta
          name="description"
          content="DayViewer is perfect for content management, especially if you need a good content calendar. You can attach files and rich information to entries and work as a team."
        />
      </Helmet>
      <CommonHeader />
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />
        <UseCaseSider />

        <Layout className="guide-body">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1>Online Content Calendar</h1>

            <Button className="homepage-signup-btn">
              <a
                href="#"
                onClick={() =>
                  window.open("https://app.dayviewer.com/auth/signup", "_self")
                }
              >
                Sign Up
              </a>
            </Button>
          </div>

          <Row>
            <div>
              <h2>Get your content organized with DayViewer.</h2>
            </div>
            <Col>
              <p>
                Any good publishing or marketing team needs to be on point when
                it comes to content management and scheduling. Making sure you
                get the content you need to get out there prepared and
                scheduled.
              </p>
              <p>
                When your content strategy is planned on one calendar planner,
                it's a lot easier to stay organized and focus on deadlines.
              </p>
              <p>
                With DayViewer, you can attach images and files to notes,
                entries and journal posts. So it can become the hub for your
                "latest version copy" - the ideal work management flow for
                content creators.
              </p>
              <p>
                Wouldn't it be great if there was one system that lets you draft
                your content (in notes or directly in calendar entries) where
                you can drop these in as tasks in your calendar, and get a
                reminder about them?.
              </p>
              <p>
                Using DayViewer as a content calendar will help you avoid
                hunting for information and will keep your system error free.
                Use labels to organize the similar content together and neatly
                and quickly refer to as required.
              </p>

              <p>
                DayViewer is an all in one planner tool, so you can do much more
                than plan content on it. We encourage using it as a calendar and
                workspace for your team. This is where you will get the full
                benefit of he DayViewer online calendar planner system.
              </p>

              <div
                style={{
                  textAlign: "center",
                  padding: 20,
                }}
              >
                {" "}
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/online-journal-640.webp"
                  ></source>
                  <source
                    type="image/jpg"
                    srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/online-journal-640.jpg"
                  ></source>
                  <img
                    src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/online-journal-640.jpg"
                    alt="online content calendar"
                    title="online content calendar"
                  />
                </picture>
              </div>
            </Col>
          </Row>
        </Layout>
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);

export default UseCaseContentCalendar;
